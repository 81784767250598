const colors = {
  dark: "#212031",
  light: "#f9f4e6",
  pink: "#ea84b3",
  purple: "#673897",
  red: "#db2c3d",
  secondaryDark: "#2e2d45",
  secondaryPink: "#eda6c7",
  violet: "#cb6cfc",
  white: "#faf9f6",
};

export default colors;
