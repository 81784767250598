import styled from "styled-components";
import colors from "../../common/constants/colors";

export const Main = styled.div`
  color: ${colors.white};
  background: ${({ primary }) =>
    primary === "true" ? colors.white : colors.dark};

  @media (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 910px;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  z-index: 1;

  @media (max-width: 768px) {
    height: fit-content;
  }
`;

export const Row = styled.div`
  display: grid;
  align-items: center;
  grid-auto-columns: minmax(auto, 1fr);
  grid-template-areas: ${({ primary }) =>
    primary === "true" ? `'col2 col1'` : `'col1 col2'`};

  @media (max-width: 768px) {
    grid-template-areas: ${({ primary }) =>
      primary === "true" ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: ${({ gridArea }) => (gridArea ? gridArea : "col1")};

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  max-width: 540px;
  margin-left: ${({ primary }) => (primary === "true" ? "35px" : "0")};
  padding-top: 0;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    margin-left: 0;
    padding-bottom: 0;
  }
`;

export const Title = styled.p`
  color: ${colors.pink};
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bolder;
  font-family: Changa;
  letter-spacing: 2px;
  line-height: 16px;
  text-transform: uppercase;
`;

export const H1 = styled.h1`
  color: ${({ primary }) => (primary === "true" ? colors.dark : colors.white)};
  margin-bottom: 24px;
  font-size: 48px;
  font-family: Changa;
  font-weight: bolder;
  letter-spacing: 1.5px;
  line-height: 1.1;

  @media (max-width: 480px) {
    font-size: 32px;
  }
`;
export const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-bottom: 15px;
`;

export const HeadlineImage = styled.img`
  object-fit: cover;
  width: 260px;
  padding: 10px;
`;

export const TextWrapper = styled.div`
  color: ${({ primary }) => (primary === "true" ? colors.dark : colors.white)};
  max-width: 440px;
  margin-bottom: 35px;
`;

export const Text = styled.p`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: lighter;
  letter-spacing: 0.75px;
  line-height: 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 30px;
    padding-top: 15px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: 640px;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-top: 30px;
    height: 340px;
  }
`;

export const Image = styled.img`
  width: 100%;
  margin: 0 10px;
  object-fit: cover;
`;
