import styled from "styled-components";
import { Link } from "react-scroll";
import colors from "../constants/colors";

export const Button = styled(Link)`
  background-image: url(${({ background }) =>
    background === "primary"
      ? "/images/button_01.png"
      : background === "secondary"
      ? "/images/button_02.png"
      : "/images/button_03.png"});
  background-size: cover;
  background-repeat: no-repeat;
  color: ${colors.light};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : "12px 48px")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "20px")};
  font-family: Changa;
  letter-spacing: 1px;
  white-space: nowrap;
  outline: none;
  border-radius: 10px;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
  }
`;
