import React from "react";
import { Background, Logo, LogoWrapper, Main, Text } from "./FooterElements";

const Footer = () => {
  return (
    <>
      <Background>
        <Main>
          <LogoWrapper>
            <Logo src="/images/logo_black_small.png" />
          </LogoWrapper>
          <Text>
            Copyright © {new Date().getFullYear()} Glazed Games Studios Ltd.
          </Text>
        </Main>
      </Background>
    </>
  );
};

export default Footer;
