import styled from "styled-components";
import colors from "../../common/constants/colors";

export const Background = styled.footer`
  background-color: ${colors.pink};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  padding: 48px 24px 60px;

  @media (max-width: 768px) {
    padding: 52px 24px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex:
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export const Logo = styled.img`
  max-width: 80px;

  @media (max-width: 415px) {
    max-width: 70px;
  }
  @media (max-width: 280px) {
    max-width: 50px;
  }
`;

export const Text = styled.p`
  color: ${colors.dark};
  font-size: 14px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;
