import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../common/components/ButtonElements";
import {
  ButtonWrapper,
  Column,
  Content,
  Grid,
  H1,
  HeadlineImage,
  HeadlineWrapper,
  Image,
  ImageWrapper,
  Main,
  Row,
  Text,
  TextWrapper,
  Title,
} from "./SectionElements";

const Section = ({
  id,
  isPrimary,
  title,
  headline,
  headlineImage,
  text,
  buttonLabel,
  buttonPath,
  buttonType,
  imagePath,
}) => {
  return (
    <>
      <Main id={id} primary={isPrimary.toString()}>
        <Grid>
          <Row primary={isPrimary.toString()}>
            <Column gridArea="col1">
              <Content primary={isPrimary.toString()}>
                <Title>{title}</Title>
                {headlineImage === "" ? (
                  <H1 primary={isPrimary.toString()}>{headline}</H1>
                ) : (
                  <HeadlineWrapper>
                    <HeadlineImage src={headlineImage} alt={headline} />
                  </HeadlineWrapper>
                )}
                <TextWrapper primary={isPrimary.toString()}>
                  {text.map((block, index) => {
                    return <Text key={index}>{block}</Text>;
                  })}
                </TextWrapper>
                {buttonLabel !== "" && (
                  <ButtonWrapper>
                    <Button
                      to={buttonPath}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact={true}
                      offset={-90}
                      background={buttonType}
                    >
                      {buttonLabel}
                    </Button>
                  </ButtonWrapper>
                )}
              </Content>
            </Column>
            <Column gridArea="col2">
              {imagePath !== "" && (
                <ImageWrapper>
                  <Image src={imagePath} alt={title + " image"} />
                </ImageWrapper>
              )}
            </Column>
          </Row>
        </Grid>
      </Main>
    </>
  );
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  headlineImage: PropTypes.string.isRequired,
  text: PropTypes.array.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonPath: PropTypes.string.isRequired,
  buttonType: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
};

export default Section;
