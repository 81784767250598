import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";
import colors from "../../common/constants/colors";

export const Main = styled.div`
  background: ${colors.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: ${({ isOpen }) => (isOpen ? "0" : "-150%")};
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  transition: 0.3s ease-in-out;
  z-index: 99;
`;

export const IconWrapper = styled.div`
  color: ${colors.white};
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;

  > svg {
    font-size: 3rem;
  }

  @media (min-width: 768px) {
    top: 2.5rem;
    right: 2.5rem;
    > svg {
      font-size: 4rem;
    }
  }
  @media (min-width: 912px) {
    top: 2.5rem;
    right: 2.5rem;
    > svg {
      font-size: 6.5rem;
    }
  }
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  height: 50%;
`;

export const MenuLink = styled(LinkScroll)`
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  font-size: 2rem;
  font-family: Changa;
  letter-spacing: 3.5px;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${colors.pink};
    transition: 0.2s ease-in-out;
  }

  @media (min-width: 768px) {
    font-size: 4rem;
  }
  @media (min-width: 912px) {
    font-size: 5.5rem;
  }
`;
