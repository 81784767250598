import React from "react";
import PropTypes from "prop-types";
import { IconWrapper, Main, Menu, MenuLink } from "./SidebarElements";
import CloseIcon from "@mui/icons-material/Close";

const Sidebar = ({ isOpen, handleClose }) => {
  return (
    <>
      <Main isOpen={isOpen} onClick={handleClose}>
        <IconWrapper onClick={handleClose}>
          <CloseIcon />
        </IconWrapper>
        <Menu>
          <MenuLink
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            exact={true}
            offset={-100}
            onClick={handleClose}
          >
            About Us
          </MenuLink>
          <MenuLink
            to="games"
            smooth={true}
            duration={500}
            spy={true}
            exact={true}
            offset={-100}
            onClick={handleClose}
          >
            Games
          </MenuLink>
          <MenuLink
            to="team"
            smooth={true}
            duration={500}
            spy={true}
            exact={true}
            offset={-100}
            onClick={handleClose}
          >
            Our Team
          </MenuLink>
          <MenuLink
            to="contact"
            smooth={true}
            duration={500}
            spy={true}
            exact={true}
            offset={-100}
            onClick={handleClose}
          >
            Contact
          </MenuLink>
        </Menu>
      </Main>
    </>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Sidebar;
