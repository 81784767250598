import React, { useState } from "react";
import useWindowSize from "../common/hooks/useWindowSize";
import {
  ContactSection,
  Footer,
  Header,
  Hero,
  Section,
  Sidebar,
  TeamSection,
} from "../components";
import { aboutSection, gamesSection } from "../components/Section/Data";

const Home = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [windowWidth] = useWindowSize();

  return (
    <>
      <Header handleMenuClick={toggleSidebar} />
      {windowWidth < 960 && (
        <Sidebar isOpen={isSidebarOpen} handleClose={toggleSidebar} />
      )}
      <Hero />
      <Section {...aboutSection} />
      <Section {...gamesSection} />
      <TeamSection isPrimary={false} />
      <ContactSection isPrimary={true} />
      <Footer />
    </>
  );
};

export default Home;
