export const aboutSection = {
  id: "about",
  isPrimary: false,
  title: "About Us",
  headline: "We are bringing amazing new worlds to life",
  headlineImage: "",
  text: [
    "Join us on our journey, as we create fantastical worlds for players to traverse in. Where their choices and actions have direct consequences on the environments around them.",
    "Through the use of dynamic frameworks we aim to bring to our players and experience that pushes past the boundaries of imagination. They will get to live and breathe in wild new lands enriched with story-telling and intrigue. Welcome to the Donut Diner will not only be our flagship project, but will also pioneer our goals to enrich player experience. So keep your eyes peeled for Chapter One, which is set to release Summer 2022.",
  ],
  buttonLabel: "Meet our team",
  buttonPath: "team",
  buttonType: "secondary",
  imagePath: "/images/world1.jpg",
};

export const gamesSection = {
  id: "games",
  isPrimary: true,
  title: "Games",
  headline: "Welcome to the Donut Diner",
  headlineImage: "/images/diner_logo_small.png",
  text: [
    "Join our protagonist Denver as they are thrust into the mysteries of the Diner. This will become their new normal, as they uncover and explore beyond space, in this cast cosmic story, silence has the lease. Welcome to the Donut Diner offers a series of compelling moral challenges where the actions you take could very well put the weight of the world on your shoulders.",
  ],
  buttonLabel: "",
  buttonPath: "",
  buttonType: "",
  imagePath: "/images/diner.jpg",
};
