import styled from "styled-components";
import colors from "../../common/constants/colors";

export const Main = styled.div`
  background: ${colors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 740px;
  padding: 0 30px;
  z-index: 1;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  max-width: 1200px;
  padding: 8px 24px;
  z-index: 3;

  @media (max-width: 768px) {
    justify-content: space-evenly;
    height: 75%;
    padding-top: 80px;
  }
`;

export const Logo = styled.img`
  max-width: 420px;
  margin-top: 48px;

  @media (max-width: 415px) {
    max-width: 290px;
  }
  @media (max-width: 280px) {
    max-width: 230px;
  }
`;
