import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { animateScroll as scroll } from "react-scroll";
import {
  IconWrapper,
  Logo,
  LogoWrapper,
  Main,
  Menu,
  MenuItem,
  MenuLink,
  Nav,
  SocialLink,
  Socials,
} from "./HeaderElements";
import MenuIcon from "@mui/icons-material/Menu";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Header = ({ handleMenuClick }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 100) setIsScrolling(true);
    else setIsScrolling(false);
  };

  return (
    <>
      <Main isScrolling={isScrolling}>
        <Nav>
          <LogoWrapper to="/" onClick={() => scroll.scrollToTop()}>
            <Logo src="/images/logo_white_small.png" />
          </LogoWrapper>
          <IconWrapper onClick={handleMenuClick}>
            <MenuIcon />
          </IconWrapper>
          <Menu>
            <MenuItem>
              <MenuLink
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                exact={true}
                offset={-100}
              >
                About Us
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink
                to="games"
                smooth={true}
                duration={500}
                spy={true}
                exact={true}
                offset={-100}
              >
                Games
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink
                to="team"
                smooth={true}
                duration={500}
                spy={true}
                exact={true}
                offset={-100}
              >
                Our Team
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink
                to="contact"
                smooth={true}
                duration={500}
                spy={true}
                exact={true}
                offset={-100}
              >
                Contact
              </MenuLink>
            </MenuItem>
          </Menu>
          <Socials>
            <SocialLink
              href="https://www.instagram.com/glazedgamesstudios/"
              target="_blank"
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/Glazed-Games-Studios-108469155092834/"
              target="_blank"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/games_glazed" target="_blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/glazed-games-studios"
              target="_blank"
            >
              <LinkedInIcon />
            </SocialLink>
          </Socials>
        </Nav>
      </Main>
    </>
  );
};

Header.propTypes = {
  handleMenuClick: PropTypes.func.isRequired,
};

export default Header;
