import styled from "styled-components";
import colors from "../../common/constants/colors";

export const Background = styled.div`
  background: ${({ primary }) =>
    primary === "true" ? colors.white : colors.dark};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 560px;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 90px 24px;
  z-index: 1;

  @media (max-width: 768px) {
    height: auto;
    padding: 100px 24px;
  }
`;

export const Title = styled.p`
  color: ${colors.pink};
  margin-bottom: 16px;
  padding-top: 50px;
  font-size: 18px;
  font-weight: bolder;
  font-family: Changa;
  letter-spacing: 2px;
  line-height: 16px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

export const Socials = styled.div`
  color: ${({ primary }) => (primary === "true" ? colors.dark : colors.white)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding: 32px 0 16px;

  @media (max-width: 768px) {
    width: 260px;
  }
`;

export const SocialLink = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  > svg {
    font-size: 2.4rem;
  }
`;
