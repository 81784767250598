import styled from "styled-components";
import colors from "../../common/constants/colors";

export const Image = styled.img`
  width: 160px;
  height: 160px;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: all 0.4s ease 0.3s;
`;

export const H1 = styled.h1`
  color: ${colors.light};
  text-align: center;
  margin-bottom: 6px;
  font-size: 20px;
  transition: all 0.4s ease 0.2s;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const H2 = styled.h2`
  color: ${colors.pink};
  text-align: center;
  margin-bottom: 6px;
  font-size: 18px;
  font-family: Changa;
  font-weight: normal;
  line-height: 19px;
  transition: all 0.4s ease 0.2s;
`;

export const TextWrapper = styled.div`
  max-width: 195px;
  position: absolute;
  opacity: 0;
  transform: translateY(-30px);
  transition: all 0.4s ease 0.1s;
`;

export const Text = styled.p`
  color: ${colors.dark};
  font-size: 16px;
`;

export const Socials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;

export const SocialLink = styled.a`
  color: ${colors.dark};
  padding: 0 7px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.pink};
    transition: 0.2s ease-in-out;
  }
`;

export const Main = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 230px;
  max-height: 380px;
  padding: 20px;
  box-shadow: none;
  transition: all 0.4s ease 0.2s;

  &:hover {
    background: ${colors.white};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease;
  }

  &:hover ${Image} {
    opacity: 0;
    transform: scale(1.2);
    transition: all 0.2s ease;
  }

  &:hover ${H1} {
    color: ${colors.dark};
    transform: translateY(20px);
    transition: all 0.4s ease;
  }

  &:hover ${H2} {
    transform: translateY(15px);
    transition: all 0.4s ease;
  }

  &:hover ${TextWrapper} {
    transition: all 0.4s ease;
    opacity: 1;
  }
`;
