import React from "react";
import PropTypes from "prop-types";
import {
  Background,
  Main,
  Socials,
  SocialLink,
  Title,
} from "./ContactSectionElements";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const ContactSection = ({ isPrimary }) => {
  return (
    <>
      <Background primary={isPrimary.toString()}>
        <Main id="contact">
          <Title>Contact</Title>
          <Socials primary={isPrimary.toString()}>
            <SocialLink
              href="https://www.instagram.com/glazedgamesstudios/"
              target="_blank"
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/Glazed-Games-Studios-108469155092834/"
              target="_blank"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/games_glazed" target="_blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/glazed-games-studios"
              target="_blank"
            >
              <LinkedInIcon />
            </SocialLink>
          </Socials>
        </Main>
      </Background>
    </>
  );
};

ContactSection.propTypes = {
  isPrimary: PropTypes.bool.isRequired,
};

export default ContactSection;
