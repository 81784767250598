import React from "react";
import PropTypes from "prop-types";
import {
  H1,
  H2,
  Image,
  Main,
  SocialLink,
  Socials,
  Text,
  TextWrapper,
} from "./MemberCardElements";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkIcon from "@mui/icons-material/Link";

const MemberCard = ({ name, role, bio, imagePath, socials }) => {
  const getSocialLink = () => {
    return socials.map((social, index) => {
      return (
        <SocialLink key={index} href={social} target="_blank">
          {social.includes("instagram") ? (
            <InstagramIcon />
          ) : social.includes("facebook") ? (
            <FacebookIcon />
          ) : social.includes("twitter") ? (
            <TwitterIcon />
          ) : social.includes("linkedin") ? (
            <LinkedInIcon />
          ) : social.includes("github") ? (
            <GitHubIcon />
          ) : social.includes("artstation") ? (
            <i className="fa-brands fa-artstation" />
          ) : (
            <LinkIcon />
          )}
        </SocialLink>
      );
    });
  };

  return (
    <>
      <Main>
        <Image src={imagePath} alt={name + "'s profile picture"} />
        <TextWrapper>
          <Text>{bio}</Text>
          <Socials>{getSocialLink()}</Socials>
        </TextWrapper>
        <H1>{name}</H1>
        <H2>{role}</H2>
      </Main>
    </>
  );
};

MemberCard.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  socials: PropTypes.array.isRequired,
};

export default MemberCard;
