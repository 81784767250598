import React from "react";
import { Content, Image, ImageWrapper, Logo, Main } from "./HeroElements";

const Hero = () => {
  return (
    <>
      <Main>
        <ImageWrapper>
          <Image src="/images/hero-background.jpg" />
        </ImageWrapper>
        <Content>
          <Logo src="/images/logo_white.png" />
        </Content>
      </Main>
    </>
  );
};

export default Hero;
