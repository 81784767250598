import styled from "styled-components";
import { Link as LinkRouter } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import colors from "../../common/constants/colors";

export const Main = styled.div`
  background: ${({ isScrolling }) =>
    isScrolling ? colors.dark : "transparent"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  height: 100px;
  margin-top: -100px;
  font-size: 1.2rem;
  transition: 0.8s all ease;
  z-index: 10;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  padding: 0 24px;
  z-index: 1;
`;

export const LogoWrapper = styled(LinkRouter)`
  display: flex;
  justify-self: flex-start;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
`;

export const Logo = styled.img`
  max-width: 90px;

  @media (max-width: 415px) {
    max-width: 70px;
  }
  @media (max-width: 280px) {
    max-width: 50px;
  }
`;

export const IconWrapper = styled.div`
  display: none;

  @media (max-width: 960px) {
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-right: 1rem;
    cursor: pointer;

    > svg {
      font-size: 1.8rem;
    }
  }
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0;
  list-style: none;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const MenuItem = styled.li`
  height: 100px;
`;

export const MenuLink = styled(LinkScroll)`
  color: ${colors.white};
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 32px;
  text-decoration: none;
  font-family: Changa;
  font-size: 20px;
  letter-spacing: 2px;
  cursor: pointer;

  &:hover {
    color: ${colors.pink};
    transition: 0.2s ease-in-out;
  }

  &.active {
    border-bottom: 3px solid ${colors.pink};
  }
`;

export const Socials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const SocialLink = styled.a`
  color: ${colors.white};
  margin-right: 1rem;
  padding: 0 0.5rem;
  text-decoration: none;
  cursor: pointer;

  > svg {
    font-size: 1.8rem;
  }

  &:hover {
    color: ${colors.pink};
    transition: 0.2s ease-in-out;
  }
`;
