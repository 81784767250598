export const artTeam = [
  {
    name: "Cody Schroeder",
    role: "Art Lead and Concept Artist",
    bio: "Cody enjoys playing piano, video games, cooking and trying new food. Always looking to make connections and friends in the industry.",
    imagePath: "/images/members/Cody_Schroeder.jpg",
    socials: [
      "https://www.instagram.com/cody_cas",
      "https://www.linkedin.com/in/cody-schroeder-883853197",
      "http://codyschroeder.myportfolio.com",
    ],
  },
  {
    name: "Cynthia Sipes",
    role: "UX/UI Lead",
    bio: "Cynthia wants to help create a fun and meaningful experience for others and have fun along the way.",
    imagePath: "/images/members/Cynthia_Sipes.jpg",
    socials: [
      "https://www.linkedin.com/in/cynsipes",
      "https://www.cynthia.sipes.squarespace.com",
    ],
  },
  {
    name: "Amar Khatri",
    role: "3D Modeling Lead and Texture Artist",
    bio: "As long as he's equipped with coffee, Amar is up to challenge his skills and progress them further.",
    imagePath: "/images/members/Amar_Khatri.jpg",
    socials: [
      "https://www.artstation.com/ak93",
      "https://www.linkedin.com/in/amar-khatri",
    ],
  },
  {
    name: "Nyla Laurie",
    role: "Environment Artist and 3D Modeler",
    bio: "Nyla enjoys a realistic style and creating comfy scenes. Besides playing games, in her free time she loves exploring new cafes and trying afternoon teas.",
    imagePath: "/images/members/Nyla_Laurie.jpg",
    socials: [
      "https://twitter.com/FaltiraArt",
      "https://www.artstation.com/faltira",
      "https://nylalaurie.com",
    ],
  },
  {
    name: "Nakiya Qaidjoher",
    role: "UI Artist and 3D Prop Artist",
    bio: "Nkia loves playing games and is a huge bookworm. In her free time, she likes watching movies and taking walks.",
    imagePath: "/images/members/Nakiya_Qaidjoher.jpg",
    socials: [
      "https://www.artstation.com/nkiaqaeidjoher",
      "https://www.linkedin.com/in/nakiya-qaeidjoher-8451bb17a",
    ],
  },
  {
    name: "Sidharth Muraly Nair",
    role: "3D Artist",
    bio: '"As a person who has loved playing games from when I was 7, I always pondered over the stories of characters and props."',
    imagePath: "/images/members/Sid_Nair.jpg",
    socials: ["https://www.artstation.com/dazai_llama"],
  },
  {
    name: "Rachel 'Gray' Hill",
    role: "3D Artist",
    bio: "Rachel is an artist and game production student, inspired to make games look cool in any way that she can.",
    imagePath: "/images/members/Rachel_Hill.jpg",
    socials: [],
  },
  {
    name: "Megs 'Pine' Joyce",
    role: "Concept Artist",
    bio: "Pine graduated in July 2022 from a Comic and Concept Art course and takes a lot of inspiration from fantasy.",
    imagePath: "/images/members/Megs_Joyce.png",
    socials: [
      "https://twitter.com/piiyne",
      "https://www.artstation.com/piiyne",
      "https://piiyne.carrd.co",
    ],
  },
  {
    name: "Dasha Marsh",
    role: "Character Modeller",
    bio: '"I loves gaming, and games such as The Witcher 3 and RDR2 have greatly inspired my style and love for character creation."',
    imagePath: "/images/members/Dasha_Marsh.jpg",
    socials: [
      "https://www.instagram.com/__.polaris.__",
      "https://twitter.com/polaris_730",
      "https://www.artstation.com/dasha_marsh",
    ],
  },
];

export const designTeam = [
  {
    name: "Erin England",
    role: "Lead Level Designer",
    bio: '"My goal is to make exciting and unique levels that tell stories, immerse the player, and create fun and memorable experiences."',
    imagePath: "/images/members/Erin_England.jpg",
    socials: [
      "https://www.linkedin.com/in/erin-england",
      "https://erinengland.carbonmade.com",
    ],
  },
];

export const narrativeTeam = [
  {
    name: "Talita Du Plooy",
    role: "Narrative Designer",
    bio: "Talita is a freelancer, jack-of-all-trades, master of none, and they definitely prefer story development above all else.",
    imagePath: "/images/members/Talita_Du_Plooy.jpg",
    socials: [
      "https://www.instagram.com/talkumiarts",
      "https://twitter.com/Talkumiart",
      "https://www.linkedin.com/in/talita-du-plooy-214777154",
    ],
  },
];

export const programmingTeam = [
  {
    name: "Marcelo de Souza Camargo",
    role: "Lead Programmer",
    bio: "It has long been Marcelo's dream to become a writer, now he aims to bring to life complex narratives in the form of pixels and polygons, instead of words on a page.",
    imagePath: "/images/members/Marcelo_Camargo.jpg",
    socials: [
      "https://www.linkedin.com/in/marcelocsc",
      "https://github.com/MarceloCSC",
    ],
  },
  {
    name: "Ben Russel",
    role: "Systems Architect",
    bio: "While at home in Visual Studio, he also enjoys creating art such as writing and painting. All-around nerd and sci-fi enthusiast, the future of humanity is at the forefront of his mind.",
    imagePath: "/images/members/Ben_Russel.jpg",
    socials: [
      "https://twitter.com/BenRussellGames",
      "https://www.brussellgames.com",
    ],
  },
  {
    name: "AJ Jones",
    role: "Narrative / Dialogue Programmer",
    bio: "AJ currently works as a technical artist at an ed-tech start-up, and loves playing Animal Crossing.",
    imagePath: "/images/members/AJ.jpg",
    socials: [
      "https://twitter.com/aaayejaaayesims",
      "https://www.tiktok.com/@user3986217436393",
    ],
  },
  {
    name: "Yuval Winter",
    role: "UI and Enemy AI Programmer",
    bio: "Yuval was interested in game making since he was 12, and he always looks up for new interesting opportunities to take part in.",
    imagePath: "/images/members/Yuval_Winter.jpg",
    socials: [],
  },
];

export const soundTeam = [
  {
    name: "John Stolzle",
    role: "Project / Audio Director and Sound Designer",
    bio: "The audio environment has a meticulously curated sound palette steeped heavily in #synthesizers & #fieldrecording.",
    imagePath: "/images/members/John_Stolzle.jpg",
    socials: ["https://twitter.com/AmbientArtstyls", "https://demat.so/"],
  },
];

export const voiceActingTeam = [
  {
    name: "Hanna Offer",
    role: "Voice Actor (Denver)",
    bio: "They are an anime and game addict as well as a workaholic, splitting their career between design, development and acting.",
    imagePath: "/images/members/Hanna_Offer.jpg",
    socials: [
      "https://twitter.com/hannahofervo",
      "https://www.hannahofervo.com/",
    ],
  },
  {
    name: "Luca Xavier",
    role: "Voice Actor (Art) / Casting Director",
    bio: "Luca is aiming for the heavens while supporting others in the voice acting world, with a love for Soulsborne and RPGs.",
    imagePath: "/images/members/Luca_Xavier.jpg",
    socials: [
      "https://twitter.com/LucaXavierVA",
      "https://lucaxavierva.carrd.co/",
    ],
  },
  {
    name: "Michelle Jin",
    role: "Voice Actor (The Pale Woman)",
    bio: "Michelle is a New York(ian) voice actor. She is joining the team to help Glazed Games serve up some deliciousness.",
    imagePath: "/images/members/Michelle_Jin.jpg",
    socials: ["https://twitter.com/MichelleJinVO"],
  },
  {
    name: "Jenna Oliver",
    role: "Voice Actor (Mushroom Queen)",
    bio: "Besides acting, Jenna enjoys writing, travelling and going for hikes - especially to places that are allegedly haunted.",
    imagePath: "/images/members/Jenna_Oliver.jpg",
    socials: ["https://www.jennaoliver.ca/"],
  },
];

export const marketingTeam = [
  {
    name: "Kelly ‘Mackie’ Byrne",
    role: "Community Engagement",
    bio: "Dedicated social media loving human who is a top gun in sales.",
    imagePath: "/images/members/Mackie_Byrne.png",
    socials: [
      "https://www.instgram.com/kel88star",
      "https://twitter.com/babybrit",
      "http://www.linkedin.com/in/kelstar",
    ],
  },
  {
    name: "Tony Ray",
    role: "Director of Promotional Art",
    bio: "When he’s not designing or working through his gaming backlog, Tony records the podcast Spawn Camp with friends.",
    imagePath: "/images/members/Tony_Ray.jpg",
    socials: ["https://linktr.ee/TonyRayUK"],
  },
];
