import styled from "styled-components";
import colors from "../../common/constants/colors";

export const Background = styled.div`
  background: ${({ primary }) =>
    primary === "true" ? colors.white : colors.dark};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 90px 24px;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 100px 24px;
  }
`;

export const Content = styled.div`
  margin-left: ${({ primary }) => (primary === "true" ? "35px" : "0")};
  padding-top: 50px;

  @media (max-width: 768px) {
    margin-left: 0;
    padding-top: 0;
  }
`;

export const Title = styled.p`
  color: ${colors.pink};
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bolder;
  font-family: Changa;
  letter-spacing: 2px;
  line-height: 16px;
  text-transform: uppercase;
`;

export const H1 = styled.h1`
  color: ${({ primary }) => (primary === "true" ? colors.dark : colors.white)};
  max-width: 680px;
  margin-bottom: 24px;
  font-size: 48px;
  font-family: Changa;
  font-weight: bolder;
  letter-spacing: 1.5px;
  line-height: 1.1;

  @media (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Text = styled.p`
  color: ${({ primary }) => (primary === "true" ? colors.dark : colors.white)};
  max-width: 800px;
  margin-bottom: 35px;
  font-size: 20px;
  font-weight: lighter;
  letter-spacing: 0.75px;
  line-height: 24px;
`;

export const H2 = styled.h2`
  color: ${colors.pink};
  font-size: 28px;
  font-family: Changa;
  font-weight: bolder;
  letter-spacing: 1.5px;
  padding: 20px 0;
`;

export const Members = styled.div`
  padding: 0 15px 15px;
  width: 800px;

  @media (max-width: 768px) {
    padding: 0 10px 15px;
    width: auto;
  }
`;

export const Grid = styled.div`
  display: grid;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
