import React from "react";
import PropTypes from "prop-types";
import {
  Background,
  Content,
  Grid,
  H1,
  H2,
  Main,
  Members,
  Text,
  Title,
} from "./TeamSectionElements";
import MemberCard from "./MemberCard";
import {
  artTeam,
  designTeam,
  marketingTeam,
  narrativeTeam,
  programmingTeam,
  soundTeam,
  voiceActingTeam,
} from "./Data";

const TeamSection = ({ isPrimary }) => {
  return (
    <>
      <Background primary={isPrimary.toString()}>
        <Main id="team">
          <Content primary={isPrimary.toString()}>
            <Title>Our Team</Title>
            <H1 primary={isPrimary.toString()}>
              Talent from all around the world
            </H1>
            <Text primary={isPrimary.toString()}>
              Our project would not have been possible without our amazing,
              talented team. Their expertise has brought our humble ideas to
              life. Combined, our cast and production crew space across 12
              countries and more than 8 timezones. They have shown that with
              dedication, talent and passion anything is possible. We pride
              ourselves on our teamwork, and value everyone who has helped
              produce our epic adventures.
            </Text>
          </Content>

          {artTeam.length > 0 && (
            <Members>
              <H2>Art Team</H2>
              <Grid>
                {artTeam.map((member, index) => {
                  return (
                    <MemberCard
                      key={index}
                      name={member.name}
                      role={member.role}
                      bio={member.bio}
                      imagePath={member.imagePath}
                      socials={member.socials}
                    />
                  );
                })}
              </Grid>
            </Members>
          )}

          {designTeam.length > 0 && (
            <Members>
              <H2>Design Team</H2>
              <Grid>
                {designTeam.map((member, index) => {
                  return (
                    <MemberCard
                      key={index}
                      name={member.name}
                      role={member.role}
                      bio={member.bio}
                      imagePath={member.imagePath}
                      socials={member.socials}
                    />
                  );
                })}
              </Grid>
            </Members>
          )}

          {narrativeTeam.length > 0 && (
            <Members>
              <H2>Narrative Team</H2>
              <Grid>
                {narrativeTeam.map((member, index) => {
                  return (
                    <MemberCard
                      key={index}
                      name={member.name}
                      role={member.role}
                      bio={member.bio}
                      imagePath={member.imagePath}
                      socials={member.socials}
                    />
                  );
                })}
              </Grid>
            </Members>
          )}

          {programmingTeam.length > 0 && (
            <Members>
              <H2>Programming Team</H2>
              <Grid>
                {programmingTeam.map((member, index) => {
                  return (
                    <MemberCard
                      key={index}
                      name={member.name}
                      role={member.role}
                      bio={member.bio}
                      imagePath={member.imagePath}
                      socials={member.socials}
                    />
                  );
                })}
              </Grid>
            </Members>
          )}

          {soundTeam.length > 0 && (
            <Members>
              <H2>Sound Team</H2>
              <Grid>
                {soundTeam.map((member, index) => {
                  return (
                    <MemberCard
                      key={index}
                      name={member.name}
                      role={member.role}
                      bio={member.bio}
                      imagePath={member.imagePath}
                      socials={member.socials}
                    />
                  );
                })}
              </Grid>
            </Members>
          )}

          {voiceActingTeam.length > 0 && (
            <Members>
              <H2>Voice Acting</H2>
              <Grid>
                {voiceActingTeam.map((member, index) => {
                  return (
                    <MemberCard
                      key={index}
                      name={member.name}
                      role={member.role}
                      bio={member.bio}
                      imagePath={member.imagePath}
                      socials={member.socials}
                    />
                  );
                })}
              </Grid>
            </Members>
          )}

          {marketingTeam.length > 0 && (
            <Members>
              <H2>Marketing</H2>
              <Grid>
                {marketingTeam.map((member, index) => {
                  return (
                    <MemberCard
                      key={index}
                      name={member.name}
                      role={member.role}
                      bio={member.bio}
                      imagePath={member.imagePath}
                      socials={member.socials}
                    />
                  );
                })}
              </Grid>
            </Members>
          )}
        </Main>
      </Background>
    </>
  );
};

TeamSection.propTypes = {
  isPrimary: PropTypes.bool.isRequired,
};

export default TeamSection;
